<template>
  <a-select
    v-model:value="code"
    label-in-value
    placeholder="Выберите код упаковки"
    style="width: 100%"
    :filter-option="false"
    :options="data"
    @change="changeCode($event, props.recordId)"
    @focus="editCode(props.recordId)"
  >
    <template v-if="fetching" #notFoundContent>
      <a-spin size="small"/>
    </template>
  </a-select>
</template>

<script setup>

import {ref, watch} from "vue";

const props = defineProps({
  codeValue: {
    type: Object,
    default: null,
  },
  codeData: {
    type: Array,
    default: () => [],
  },
  recordId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['edit-code', 'change-code']);

const data = ref([]);
const code = ref(null);
const fetching = ref(false);

const editCode = (id) => {
  emit('edit-code', id);
}

const changeCode = (event, id) => {
  emit('change-code', {event, id});
}



watch(
  () => props.codeData,
  (value) => {
    if (value) {
      data.value = value;
    } else {
      data.value = [];
    }
  },
);
watch(
  () => props.codeValue,
  (value) => {
    if (value) {
      code.value = value;
    }else {
      code.value = null;
    }
  },
);

</script>
