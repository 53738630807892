<template>
  <a-modal
    class="spic-modal"
    title="Обновить ИКПУ"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="false"
    :ok-button-props="{}"
    width="50%"
    ok-text="Сохранить"
    @ok="saveModal"
    @cancel="modalVisible = false"
  >
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      width="100%"
    >
      <template #spic="{ record }">
        <div class="row" v-if="editSpicId === record.id">
          <div class="col-md-10 px-0">
            <a-select
              ref="spicRef"
              v-model:value="spic"
              mode="multiple"
              label-in-value
              placeholder="Поиск икпу"
              style="width: 100%; max-width: 340px"
              :filter-option="false"
              :not-found-content="fetching ? undefined : null"
              :options="spicData"
              @search="fetchSpic($event, record.id)"
            >
              <template v-if="fetching" #notFoundContent>
                <a-spin size="small"/>
              </template>
            </a-select>
          </div>
          <div class="col-md-2 px-0 justify-content-end row">
            <a-button @click="saveSpic(record)" type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="product-spic">
          <div class="w-100">
            <a-tooltip @click="onEditSpicId(record.id)" placement="top" v-if="record">
              <template #title>
                <div class="text-center">{{ record.name }}</div>
              </template>
              <div class="product-ellipsis">{{ record.name }}</div>
            </a-tooltip>
            <a-typography-text v-if="record" strong>
              <CopyOutlined/>
              {{ record.spic }}
            </a-typography-text>
            <a-typography-text v-if="!record" type="secondary">(ИКПУ не задан)</a-typography-text>
          </div>
          <a-button @click="onEditSpicId(record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #code="{ record }">
        <div class="row">
          <div class="col-md-9 px-0">
            <code-select
              :key="selectKey"
              :code-value="code"
              :code-data="codeData"
              :record-id="record['id']"
              @edit-code="editCode"
              @change-code="onChangeCode"
            />
          </div>
          <div class="col-md-3 justify-content-end row p-0">
          </div>
        </div>
        <div class="product-code">
          <div class="w-100">
          </div>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import {CopyOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons-vue";
import {nextTick, reactive, ref, toRefs, watch} from "vue";
import store from "store";
import {debounce} from "lodash";
import apiClient from "@/services/axios";
import {message} from "ant-design-vue";
import {useRoute} from "vue-router";
import CodeSelect from "@/views/store/menu/products/components/code-select.vue";

const props = defineProps({
  modal: {
    type: Boolean,
    default: ref(false),
  },
  brand: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["close-spic-modal"]);
const modalVisible = ref(false);
const spic = ref(null);
const code = ref(null);
const fetching = ref(false);
const loading = ref(false);
const editSpicId = ref(null);
const editCodeId = ref(null);
const selectKey = ref(0);
const spicData = ref([]);
const codeData = ref([]);
const dataSource = ref([]);
const spicAndCodeModel = ref([]);
const spicRef = ref("spicRef");
const route = useRoute();
const columns = [
  {
    title: 'ИКПУ',
    dataIndex: 'spic',
    key: 'spic',
    width: 300,
    slots: {customRender: 'spic'},
  },
  {
    title: 'Код упаковки',
    dataIndex: 'code',
    key: 'code',
    width: 250,
    slots: {customRender: 'code'},
  },
];
const initialParams = {
  page: 1,
  'per-page': 10,
  sort: '',
  name: '',
};
const queryParams = reactive({...initialParams});

const saveSpic = (model) => {
  let value;
  if (spic.value) {
    if (spic.value.length) {
      value = spic.value.find(() => true)?.value
    } else {
      value = spic.value?.value
    }
  }
  if (!value)
    value = 'null'

  apiClient.put(`/menu/product-spic-update?category=${route.params.category}`, {
    oldspic: model.id,
    spic: value,
  }).then(response => {
    if (response) {
      message.success('ИКПУ успешно обновлен')
      codeData.value = response.data.data;
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    reload()
  });
  editSpicId.value = 0
}

const onChangeCode = ({event, id}) => {
  const obj = spicAndCodeModel.value.find(model => {
    return model['spic'] === id
  });

  if (obj)
    obj.code = event.key;
}
const getData = () => {
  loading.value = true
  return apiClient.get(`/menu/product-unique-spic?category=${route.params.category}&brand=${props.brand}`).then(({data}) => {
    dataSource.value = data.data
    loading.value = false
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    loading.value = false
  });
}
const fetchSpic = debounce((value, product) => {
  if (value.length < 17)
    return false;

  spicData.value = [];
  fetching.value = true;
  const accessToken = store.get('accessToken')
  const apiUrl = process.env.VUE_APP_BACKEND_URL;
  fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=${product}&value=${value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": `Bearer ${accessToken}`,
    },
  })
    .then(response => response.json())
    .then(({data}) => {
      // if (fetchId !== lastFetchId) {
      //   // for fetch callback order
      //   return;
      // }
      spicData.value = data
      fetching.value = false;
    });
}, 300);
const fetchCode = (spicId) => {
  editCodeId.value = spicId;
  codeData.value = [];
  fetching.value = true;
  const accessToken = store.get('accessToken');
  const apiUrl = process.env.VUE_APP_BACKEND_URL;
  fetch(`${apiUrl}/cabinet/menu/fetch-code?spic=${spicId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": `Bearer ${accessToken}`,
    },
  })
    .then(response => response.json())
    .then(({data}) => {
      codeData.value = data;
      fetching.value = false;
    });
}
const editCode = (spic) => {
  fetchCode(spic);
}
const saveModal = () => {
  const uuid = route.params.category;
  return apiClient.post(`menu/save-unique-spic?category=${uuid}`, {
    body: spicAndCodeModel.value,
  }).then((response) => {
    if (response) {
      message.success('Продукты успешно обновлены');
    }
  }).catch(() => {
    modalVisible.value = true;
    // console.log(err)
  }).finally(() => {
    modalVisible.value = false;
    reload()
  });
}
const onEditSpicId = (id) => {
  editSpicId.value = id;
  nextTick(() => spicRef.value?.focus())
}

const reload = () => {
  getData({
    ...queryParams,
  })
};

watch(
  () => dataSource.value,
  (value) => {
    spicAndCodeModel.value = []

    for (let i = 0; i < dataSource.value.length; i++) {
      spicAndCodeModel.value.push({
        spic: dataSource.value[i]['id'],
        code: null,
      })
    }
  },
)

watch(
  () => props.modal,
  (value) => {
    if (value) {
      getData();
      editSpicId.value = editCodeId.value = null;
      spicData.value = [];
      codeData.value = [];
      code.value = null;
      selectKey.value++;
      modalVisible.value = value;
    }
  },
)
watch(
  () => modalVisible.value,
  (value) => {
    if (!value)
      emit("close-spic-modal", value);
  },
)


const query = {...toRefs(queryParams)}
</script>
