export default function Product(attrs) {
  this.name = attrs.name ? attrs.name : ''
  this.description = attrs.description ? attrs.description : ''
  this.category_id = attrs.category_id ? attrs.category_id : null
  this.big_image_url = attrs.big_image_url ? attrs.big_image_url : ''
  this.image_url = attrs.image_url ? attrs.image_url : ''
  this.price = attrs.price ? attrs.price : null
  this.spic = attrs.spic ? attrs.spic : null
  this.code = attrs.code ? attrs.code : null
  this.spicModel = attrs.spicModel ? attrs.spicModel : {}
  this.codeModel = attrs.codeModel ? attrs.codeModel : {}
  this.locales = attrs.locales ? attrs.locales : []
}
